import ScrollTo from "stimulus-scroll-to"

export default class extends ScrollTo {
  connect() {
    super.connect()
  }

  get defaultOptions() {
    return {
      offset: 0,
      behavior: "auto"
    }
  }
}
