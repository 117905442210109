import { Controller } from "stimulus"
import { useThrottle } from "stimulus-use"

export default class extends Controller {
  static throttles = ["shrink"]
  static classes = ["shrink"]

  topMargin = 80

  connect() {
    useThrottle(this, { wait: 100 })
    this.shrink()
  }

  shrink() {
    this.element.classList.toggle(this.shrinkClass, this.shouldShrink)
    this.lastScrollTop = this.scrollTop
  }

  get scrollTop() {
    return window.pageYOffset || document.documentElement.scrollTop
  }

  get shouldShrink() {
    return (
      this.scrollTop > this.topMargin && this.scrollTop > this.lastScrollTop
    )
  }
}
