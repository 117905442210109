import Rails from "@rails/ujs"
Rails.start()

import "@hotwired/turbo-rails"
import "@asgerb/apparatus/package/dist/application.js"

import "controllers"

require.context("../images", true)

function setLangAttribute() {
  const meta = document.querySelector("meta[name=language]")
  if (!meta) return
  const language = meta.getAttribute("content")
  document.documentElement.setAttribute("lang", language)
}

function setPageData() {
  const documentElement = document.documentElement
  const bodyElement = document.body
  documentElement.dataset.path = bodyElement.dataset.path
  documentElement.dataset.pageType = bodyElement.dataset.pageType
  documentElement.classList = bodyElement.classList
}

document.addEventListener("turbo:load", function () {
  setLangAttribute()
  setPageData()
})
