import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import { registerControllers } from "@asgerb/apparatus-layout"

const application = Application.start()
const controllers = require.context("controllers", true, /_controller\.js$/)
const componentControllers = require.context(
  "../../components",
  true,
  /_controller\.js$/
)

application.load(
  definitionsFromContext(controllers).concat(
    definitionsFromContext(componentControllers)
  )
)
registerControllers(application)
application.warnings = false
