import Swiper, { Pagination } from "swiper"
import "swiper/swiper.min.css"
import { Controller } from "stimulus"
import { useDispatch } from "stimulus-use"

export default class extends Controller {
  static targets = ["pagination"]
  static classes = ["slide", "wrapper", "pagination"]
  static values = {
    paginationType: String
  }

  connect() {
    useDispatch(this, { eventPrefix: "swiper" })
    this.swiper = new Swiper(this.element, this.swiperOptions)
    this.swiper.onAny((eventName, args) => {
      this.dispatch(eventName, args)
    })
  }

  get swiperOptions() {
    return {
      autoHeight: true,
      grabCursor: true,
      slidesPerView: "auto",
      centeredSlides: true,
      pagination: this.paginationOptions,
      modules: this.swiperModules
    }
  }

  get paginationOptions() {
    if (!this.usePagination) return {}
    return {
      el: `.${this.paginationClass}`,
      type: this.paginationTypeValue
    }
  }

  get swiperModules() {
    let modules = []
    if (this.usePagination) modules.push(Pagination)
    return modules
  }

  get usePagination() {
    return this.hasPaginationTarget
  }
}
