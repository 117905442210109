import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["slide", "caption"]

  updateCaption({ detail }) {
    const currentSlide = this.slideTargets[detail.activeIndex]
    const caption = currentSlide.dataset.caption
    this.captionTarget.innerHTML = caption
  }
}
