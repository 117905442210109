import { Controller } from "stimulus"
import { useDispatch } from "stimulus-use"

export default class extends Controller {
  static targets = ["section", "anchor"]

  defaults = {
    threshold: [0, 0.5, 1]
  }

  connect() {
    useDispatch(this)
    this._observeTops()
    this._observeBottoms()
    this.sectionsInView = []
  }

  _observeTops() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => this._sentinelTopIntersected(entry))
      },
      { threshold: this.defaults.threshold }
    )
    const sentinels = this._addSentinels("top")
    sentinels.forEach((sentinel) => observer.observe(sentinel))
  }

  _observeBottoms() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => this._sentinelBottomIntersected(entry))
      },
      { threshold: this.defaults.threshold }
    )
    const sentinels = this._addSentinels("bottom")
    sentinels.forEach((sentinel) => observer.observe(sentinel))
  }

  _addSentinels(position) {
    if (!this.hasSectionTarget) return
    return this.sectionTargets.map((section) => {
      const sentinel = document.createElement("div")
      sentinel.classList.add("toc-sentinel", `toc-sentinel--${position}`)
      return section.appendChild(sentinel)
    })
  }

  _sentinelTopIntersected(entry) {
    const boundingRect = entry.boundingClientRect
    const rootBounds = entry.rootBounds
    const section = entry.target.parentElement
    const sectionRect = section.getBoundingClientRect()

    if (!boundingRect || !rootBounds || !sectionRect) return

    const goingIn =
      boundingRect.top <= rootBounds.top && sectionRect.bottom >= rootBounds.top
    const goingOut = boundingRect.top >= rootBounds.top

    if (goingIn) {
      this._sectionIn(section)
    }

    if (goingOut) {
      this._sectionOut(section)
    }
  }

  _sentinelBottomIntersected(entry) {
    const boundingRect = entry.boundingClientRect
    const rootBounds = entry.rootBounds
    const section = entry.target.parentElement
    const sectionRect = section.getBoundingClientRect()

    if (!boundingRect || !rootBounds || !sectionRect) return

    const goingIn =
      boundingRect.bottom >= rootBounds.top && sectionRect.top <= rootBounds.top
    const goingOut = boundingRect.bottom <= rootBounds.top

    if (goingIn) {
      this._sectionIn(section)
    }

    if (goingOut) {
      this._sectionOut(section)
    }
  }

  _sectionIn(section) {
    this._addIntersectingSection(section)
    this.dispatch("section:in", {
      section: section,
      sectionId: section.id,
      sectionsInView: this.sectionsInView
    })
    this.sectionInView = section.id
  }

  _sectionOut(section) {
    this._removeIntersectingSection(section)
    this.dispatch("section:out", {
      section: section,
      sectionId: section.id,
      sectionsInView: this.sectionsInView
    })
    if (!this.sectionsInView.length) this.sectionInView = null
  }

  _addIntersectingSection(section) {
    const index = this.sectionsInView.indexOf(section)
    if (index < 0) this.sectionsInView.push(section)
  }

  _removeIntersectingSection(section) {
    const index = this.sectionsInView.indexOf(section)
    if (index > -1) this.sectionsInView.splice(index, 1)
  }

  set sectionInView(sectionId) {
    this.data.set("sectionInView", sectionId)
    this.anchorTargets.forEach((anchor) => {
      const isInView = anchor.hash === `#${sectionId}`
      anchor.setAttribute("aria-current", isInView.toString())
      if (isInView) this.dispatch("anchor:current", { anchor: anchor })
    })
  }

  get sectionInView() {
    return this.sectionTargets.filter((section) => {
      return section.id === this.data.get("sectionInView")
    })
  }
}
